import React, { forwardRef } from "react";
import { Link as GatsbyLink } from "gatsby";
import styled, { css } from "styled-components";
import { Body1CSS } from "../typography/typography";
import scrollTo from "gatsby-plugin-smoothscroll";

const LinkCSS = css`
  ${Body1CSS}
  color: ${({ theme }) => theme.colors.awesome};
  text-decoration: none;
`;

const StyledLink = styled.a`
  ${LinkCSS}
`;

const StyledGatsbyLink = styled(GatsbyLink)`
  ${LinkCSS}
`;

function Link(props, ref) {
  const { className, children, url, onClick, id } = props;

  const isAnchor = (url) => {
    return url?.startsWith("#");
  };

  const isInternalLink = (url) => {
    return url?.[0] === "/";
  };

  const isFile = (url) => {
    const fileType = url ? url.split(".")[1] : "";
    return ["pdf", "zip", "exe", "docx", "pptx", "txt"].includes(fileType);
  };

  const onAnchorClick = (event) => {
    event.preventDefault();
    onClick?.();
    scrollTo(url);
  };

  if (isAnchor(url)) {
    return (
      <StyledLink
        id={id}
        ref={ref}
        className={className}
        href={url}
        onClick={onAnchorClick}
      >
        {children}
      </StyledLink>
    );
  } else if (isInternalLink(url) && isFile(url)) {
    return (
      <StyledLink
        id={id}
        ref={ref}
        className={className}
        href={url}
        rel="opener"
        target="_blank"
      >
        {children}
      </StyledLink>
    );
  } else if (isInternalLink(url)) {
    return (
      <StyledGatsbyLink
        id={id}
        ref={ref}
        className={className}
        to={url}
        onClick={onClick}
      >
        {children}
      </StyledGatsbyLink>
    );
  } else {
    return (
      <StyledLink
        id={id}
        ref={ref}
        className={className}
        href={url}
        onClick={onClick}
        rel="opener"
        target="_blank"
      >
        {children}
      </StyledLink>
    );
  }
}

export default styled(forwardRef(Link))``;
